import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import {
  courierLogAbsensiUseCase
} from '@/domain/usecase'
import moment from 'moment'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxPaging
} from 'devextreme-vue/data-grid'
import {
  DxLoadPanel
} from 'devextreme-vue/load-panel'

export default {
  name: 'TableCourierLogAttendance',
  components: {
    DxColumn,
    DxGrouping,
    DxDataGrid,
    DxLoadPanel,
    DxPaging
  },
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const loading = ref(false)
    const noDataText = ref('Tidak Ada Data')
    const router = useRouter()
    const focusedRowKey = ref(17)
    const showHeaderFilter = ref(true)
    const showFilterRow = ref(true)
    const autoExpandAll = ref(true)
    const selectedReceipt = ref(null)
    const position = ref({
      of: '#delivery-item-table-id'
    })
    const displayMode = ref('full')
    const data: any = ref([])

    const nullCondition = (val: any) => {
      if (val === null) {
        return 0
      }
      return val
    }

    const getAllData = async (dataFilter: any = {}) => {
      // console.log('ini data filter', dataFilter)
      loading.value = await true
      const filters = await {
        custom: `?startDate=${moment(dataFilter.createDate[0]).format('YYYY-MM-DD')}&endDate=${moment(dataFilter.createDate[1]).format('YYYY-MM-DD')}`
      }
      const response = await courierLogAbsensiUseCase.getAllCustom(filters)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      } else {
        /* eslint-disable array-callback-return */
        // response.result.forEach((element: any) => {
        //   element.NameAgenIn = element.MsAgenIdInNavigation?.Nama
        //   element.NameAgenOut = element.MsAgenIdOutNavigation?.Nama
        //   element.UsernameCourier = element.KurirMsUser?.Username
        //   data.value.push(element)
        // })
        data.value = response.result.Data.map((element: any) => {
          element.NameAgenIn = element.MsAgenIdInNavigation?.Nama
          element.PhoneAgen = element.KurirMsUser?.Phone
          element.NameAgenOut = element.MsAgenIdOutNavigation?.Nama
          element.UsernameCourier = element.KurirMsUser?.Username
          element.CreateDate = moment(element.ClockIn).format('DD MM YYYY')
          element.NamaCourier = element.KurirMsUser?.Fullname
          return element
        })
      }
      loading.value = false
    }

    // onMounted(() => {
    //   getAllData()
    // })

    return {
      data,
      loading,
      selectedReceipt,
      moment,
      getAllData,
      autoExpandAll,
      focusedRowKey,
      noDataText,
      showHeaderFilter,
      showFilterRow,
      position,
      displayMode,
      nullCondition
    }
  }
}