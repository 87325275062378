
import TableCourierLogAttendance from '@/views/master/courier/courier-log-attendance/TableCourierLogAttendance.vue'
import ListBlankCourier from '@/views/master/courier/components/ListBlankCourier.vue'
import {
  getCurrentInstance,
  ref
} from 'vue'
import moment from 'moment'

export default {
  name: 'CourierLogAttendance',
  components: {
    TableCourierLogAttendance,
    ListBlankCourier
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const isShowTable = ref(false)
    const dateFilter = ref(null) as any
    const tempDateFilter = ref(null)
    const refTableCourierLogAttendance = ref()
    const dateOp = ref()

    const saveFilterDate = async () => {
      tempDateFilter.value = null
      const filterDate = dateFilter.value.filter((x: any) => !x)
      if (filterDate.length === 0) {
        tempDateFilter.value = await dateFilter.value
        isShowTable.value = await true
        await dateOp.value.hide()
        refTableCourierLogAttendance.value.getAllData({
          createDate: tempDateFilter.value
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Gunakan range tanggal untuk filter',
          group: 'bc',
          life: 3000
        })
      }
    }

    const showDropDown = (event: any) => {
      dateOp.value.toggle(event)
    }

    // const clearFilterDate = () => {

    // }
    return {
      isShowTable,
      dateFilter,
      saveFilterDate,
      dateOp,
      showDropDown,
      refTableCourierLogAttendance,
      tempDateFilter,
      moment
    }
  }
}
