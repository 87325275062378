import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "font-bold" }
const _hoisted_12 = {
  key: 0,
  class: ""
}
const _hoisted_13 = { class: "color-green" }
const _hoisted_14 = { class: "color-blue-grey" }
const _hoisted_15 = {
  key: 1,
  class: "color-blue-grey"
}
const _hoisted_16 = {
  key: 0,
  class: ""
}
const _hoisted_17 = { class: "color-red" }
const _hoisted_18 = { class: "color-blue-grey" }
const _hoisted_19 = {
  key: 1,
  class: "color-blue-grey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxPaging = _resolveComponent("DxPaging")
  const _component_DxColumn = _resolveComponent("DxColumn")
  const _component_DxGrouping = _resolveComponent("DxGrouping")
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_DxDataGrid, {
      ref: "refTableDeliveryItem",
      id: "delivery-item-table-id",
      class: "delivery-item-table",
      "column-auto-width": true,
      "allow-column-reordering": false,
      "show-column-lines": false,
      "show-row-lines": true,
      "show-borders": true,
      "row-alternation-enabled": true,
      "allow-column-resizing": false,
      "word-wrap-enabled": true,
      "data-source": _ctx.data,
      "key-expr": "Id",
      "no-data-text": _ctx.noDataText
    }, {
      nameCouirerTemplate: _withCtx(({ data }) => [
        _createVNode("div", _hoisted_1, [
          _createVNode("p", _hoisted_2, _toDisplayString(`${data.data.NamaCourier} (${data.data?.KurirMsUser?.MsAgen?.Nama})`), 1)
        ])
      ]),
      noTelpTemplate: _withCtx(({ data }) => [
        _createVNode("div", _hoisted_3, [
          _createVNode("p", {
            class: "font-bold hover-primary cursor-pointer",
            onClick: ($event: any) => (_ctx.$goWhatsApps(data.data.PhoneAgen))
          }, _toDisplayString(`${data.data.PhoneAgen}`), 9, ["onClick"])
        ])
      ]),
      totalPakageTemplate: _withCtx(({ data }) => [
        _createVNode("div", _hoisted_4, [
          _createVNode("p", _hoisted_5, _toDisplayString(`${_ctx.nullCondition(data.data.TotalAssign)} (${_ctx.nullCondition(data.data.BeratAssign)}Kg)`), 1)
        ])
      ]),
      totalBelumScanTemplate: _withCtx(({ data }) => [
        _createVNode("div", _hoisted_6, [
          _createVNode("p", _hoisted_7, _toDisplayString(`${_ctx.nullCondition(data.data.TotalBelumScan)} (${_ctx.nullCondition(data.data.BeratBelumScan)}Kg)`), 1)
        ])
      ]),
      totalReturnsTemplate: _withCtx(({ data }) => [
        _createVNode("div", _hoisted_8, [
          _createVNode("p", _hoisted_9, _toDisplayString(`${_ctx.nullCondition(data.data.TotalKembali)} (${_ctx.nullCondition(data.data.BeratKembali)}Kg)`), 1)
        ])
      ]),
      totalDoneTemplate: _withCtx(({ data }) => [
        _createVNode("div", _hoisted_10, [
          _createVNode("p", _hoisted_11, _toDisplayString(`${_ctx.nullCondition(data.data.TotalDone)} (${_ctx.nullCondition(data.data.BeratDone)}Kg)`), 1)
        ])
      ]),
      clockInTemplate: _withCtx(({ data }) => [
        (data?.data?.ClockIn)
          ? (_openBlock(), _createBlock("div", _hoisted_12, [
              _createVNode("p", _hoisted_13, _toDisplayString(`${_ctx.moment(data?.data?.ClockIn).format('HH:mm')} WIB`), 1),
              _createVNode("p", _hoisted_14, _toDisplayString(`(${data.data.NameAgenIn})`), 1)
            ]))
          : (_openBlock(), _createBlock("p", _hoisted_15, "-"))
      ]),
      clockOutTemplate: _withCtx(({ data }) => [
        (data?.data?.ClockOut)
          ? (_openBlock(), _createBlock("div", _hoisted_16, [
              _createVNode("p", _hoisted_17, _toDisplayString(`${_ctx.moment(data?.data?.ClockOut).format('HH:mm')} WIB`), 1),
              _createVNode("p", _hoisted_18, _toDisplayString(`(${data.data.NameAgenOut})`), 1)
            ]))
          : (_openBlock(), _createBlock("p", _hoisted_19, "-"))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DxPaging, {
          "page-size": 20,
          enabled: true
        }),
        _createVNode(_component_DxColumn, {
          "group-index": 0,
          "data-field": "CreateDate",
          caption: ""
        }),
        _createVNode(_component_DxColumn, {
          "group-index": 1,
          "data-field": "NameAgenIn",
          caption: ""
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "NamaCourier",
          caption: "Nama Kurir",
          "min-width": 200,
          "cell-template": "nameCouirerTemplate",
          visible: true,
          alignment: "left"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "PhoneAgen",
          caption: "No. Telp",
          "min-width": 100,
          "cell-template": "noTelpTemplate",
          visible: true,
          alignment: "left"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "TotalAssign",
          caption: "Total Paket",
          "min-width": 125,
          alignment: "left",
          "cell-template": "totalPakageTemplate"
        }),
        _createVNode(_component_DxColumn, {
          alignment: "left",
          "data-field": "TotalBelumScan",
          caption: "Total Belum Scan",
          "min-width": 125,
          "cell-template": "totalBelumScanTemplate"
        }),
        _createVNode(_component_DxColumn, {
          alignment: "left",
          "data-field": "TotalKembali",
          caption: "Pengembalian",
          "min-width": 125,
          "cell-template": "totalReturnsTemplate"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "NamaAgenAsal",
          caption: "Terkirim",
          visible: true,
          "min-width": 125,
          "cell-template": "totalDoneTemplate"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "ClockIn",
          caption: "Masuk",
          alignment: "left",
          visible: true,
          "min-width": 125,
          "cell-template": "clockInTemplate"
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "ClockOut",
          caption: "Keluar",
          "min-width": 150,
          visible: true,
          "cell-template": "clockOutTemplate"
        }),
        _createVNode(_component_DxGrouping, {
          "auto-expand-all": _ctx.autoExpandAll,
          "context-menu-enabled": true,
          "expand-mode": "rowClick"
        }, null, 8, ["auto-expand-all"])
      ]),
      _: 1
    }, 8, ["data-source", "no-data-text"]),
    _createVNode(_component_DxLoadPanel, {
      "close-on-outside-click": false,
      visible: _ctx.loading,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.loading = $event)),
      position: _ctx.position,
      shading: true,
      "shading-color": "rgba(0,0,0,0.4)"
    }, null, 8, ["visible", "position"])
  ]))
}